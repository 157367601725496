
import Vue from "vue";

import { companyLineOfBusiness } from "@/helpers/selectOptions";
import UnderWritingQuestionList from "@/views/admin/underWritingQuestions/UnderWritingQuestionsList.vue";
interface IUnderWritingQuestionData {
  activeTab: string;
  errorMessage: string;
  successMessage: string;
}
export default Vue.extend({
  name: "UnderWritingQuestions",
  components: {
    UnderWritingQuestionList
  },
  data(): IUnderWritingQuestionData {
    return {
      activeTab: "H",
      errorMessage: "",
      successMessage: ""
    };
  },
  methods: {
    handleTabClick(tab: any, event: any) {
      console.log(tab, event);
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.$router
            .push(`/admin/underwriting-questions/create`)
            .catch(() => {});
          break;
      }
    }
  },
  computed: {
    companyLineOfBusiness() {
      return companyLineOfBusiness;
    }
  }
});
