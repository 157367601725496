var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"filters":_vm.filters,"hideAllFilters":false,"addAdvancedFilter":true,"element-loading-text":_vm.loadingText,"data":_vm.underWritingQuestions,"tableColumns":_vm.tableColumns,"hideToggleIcons":true,"totalRecords":_vm.getTotalRecords,"paginationLinks":_vm.getUnderWritingQuestionLinks,"tableRowActions":_vm.tableRowActions,"showSelectable":true,"tableActions":_vm.tableActions,"bulkMenuActions":_vm.bulkMenuActions,"targetedSearch":true,"searchColumnName":"All","targetedSearchData":[
      { key: 'All', value: '' },
      { key: 'Question', value: 'question' },
      { key: 'Companies', value: 'companies' },
      {
        key: 'Valid Response',
        value: 'validResponse'
      },
      { key: 'Needs Approval Code', value: 'needsApprovalCode' },
      { key: 'Error Message', value: 'errorMessage' }
    ]},on:{"fetch-query":_vm.fetchPageData,"tableRowClicked":_vm.viewCallback,"bulk-action-event":_vm.bulkActionHandler,"table-row-action":_vm.handleResponseAction,"selection-change":function($event){_vm.selectedDocuments = $event},"table-action":_vm.tableActionHandler}},[_c('NoResultsFound',{attrs:{"slot":"notFound","message":"No Underwriting Questions Found."},slot:"notFound"})],1),_c('ModalMessage',{attrs:{"name":"underWritingQuestionsDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"","description":"This will delete the Underwriting Questions. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: [''],
        click: () => _vm.deleteOneUnderWritingQuestion()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('underWritingQuestionsDeleteModal')
      }
    ]}}),_c('ModalBase',{attrs:{"name":"uploadUnderWritingQuestionsImportFileModal","size":"medium","clickToClose":false,"showClose":true,"title":"Upload Import File","loading":_vm.makingApiRequest,"position":"center"}},[_c('ImportsUploader',{attrs:{"defaultType":"underwriting-question","showHeading":false,"url":_vm.uploadUrl},on:{"close":function($event){return _vm.$modal.hide('uploadUnderWritingQuestionsImportFileModal')},"upload-success":_vm.fetchPageData}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }