export const underWritingQuestionsTableColumns = {
  question: {
    formatDataKey: "Question",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  companies: {
    formatDataKey: "Companies",
    formatDataValue: (arg: number[]) => removeCompany99(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    colWidth: 150
  },
  validResponse: {
    formatDataKey: "Valid Response",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2,
    colWidth: 150
  },
  needsApprovalCode: {
    formatDataKey: "Needs Approval Code",
    formatDataValue: (arg: string) => (arg ? "Yes" : "No"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    colWidth: 200
  },
  errorMessage: {
    formatDataKey: "Error Message",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 4,
    colWidth: 150
  }
};

function removeCompany99(companies: number[]): string {
  //check if companies include both colonial 20 and 99. remove 99 if so. change to 20 if only 99 exists
  const colonialCompanies = [99, 20];

  const bothColonialExists = colonialCompanies.every(value => {
    return companies.includes(value);
  });

  if (bothColonialExists) {
    const index = companies.indexOf(99);
    if (index > -1) {
      companies.splice(index, 1);
    }
  }
  const processedCompanies = companies.map((company: number) => {
    return company == 99 ? "20" : company + "";
  });
  return processedCompanies.join(", ");
}
