
import {
  underWritingQuestionMapState,
  underWritingQuestionMapActions,
  underWritingQuestionMapGetters
} from "@/store/modules/underWritingQuestion";
import { ITableWrapperColumns } from "@/types";
import Vue from "vue";
import { underWritingQuestionsTableColumns } from "./underWritingQuestionsTableColumns";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";

interface IUnderWritingQuestionData {
  errorMessage: string;
  successMessage: string;
  tableColumns: ITableWrapperColumns;
  isAdmin: boolean;
  filters: any[];
  tableRowActions: any[];
  selectedDocuments: any;
  tableActions: any[];
  loadingText: string;
  underWritingQuestionsId: string;
  bulkMenuActions: any[];
  uploadUrl: string;
}
export default Vue.extend({
  name: "UnderWritingQuestions",
  components: {
    ImportsUploader: () =>
      import("@/views/admin/imports/ListImports/ImportsUploader.vue"),
    NoResultsFound
  },
  props: {
    policyType: {
      type: String,
      default: ""
    }
  },
  data(): IUnderWritingQuestionData {
    return {
      successMessage: "",
      errorMessage: "",
      isAdmin: true,
      tableColumns: underWritingQuestionsTableColumns,
      filters: [],
      tableRowActions: [
        { label: "Edit", value: "edit", faIcon: "edit" },
        {
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        }
      ],
      underWritingQuestionsId: "",
      selectedDocuments: [],
      tableActions: ["Import Underwriting Questions", "All Activities"].sort(),
      loadingText: "",
      bulkMenuActions: [{ title: "Delete Selected", disabled: false }],
      uploadUrl: "underwriting-questions/bulk-upload"
    };
  },
  methods: {
    ...underWritingQuestionMapActions([
      "getUnderWritingQuestions",
      "deleteUnderWritingQuestion",
      "deleteBulkUnderWritingQuestions"
    ]),
    async fetchPageData(queryObject: Record<string, any>): Promise<any> {
      try {
        this.loadingText = "Loading Underwriting Questions. Please wait...";
        this.$emit("scrollTop");
        await this.getUnderWritingQuestions({
          query: { ...queryObject, policyType: this.policyType }
        });
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async bulkActionHandler(action: string) {
      switch (action) {
        case "Delete Selected": {
          try {
            this.loadingText = "Deleting endorsements. Please wait...";
            const underWritingQuestionsToBeDeletedIds = this.selectedDocuments.map(
              (document: any) => document._id
            );
            await this.deleteBulkUnderWritingQuestions(
              underWritingQuestionsToBeDeletedIds
            );
            this.$appNotifySuccess("Selected Underwriting Questions deleted");
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] || {}
            );
          } catch (error) {
            this.errorMessage = error.message;
            this.$bugSnagClient.notify(error);
          }
          break;
        }
        default:
          break;
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      this.$router
        .push(`/admin/underwriting-questions/${_id}/edit`)
        .catch(() => {});
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "edit":
          this.viewCallback(data);
          break;
        case "delete":
          this.deleteCallback(data._id);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteCallback(id: any): Promise<void> {
      try {
        this.underWritingQuestionsId = id;
        this.$modal.show("underWritingQuestionsDeleteModal");
      } catch (error) {
        if (error !== "cancel") {
          console.error(error.message);
        }
        this.$bugSnagClient.notify(error);
      }
    },
    async deleteOneUnderWritingQuestion(): Promise<void> {
      try {
        this.loadingText = "Deleting underWritingQuestions. Please wait...";
        await this.deleteUnderWritingQuestion(this.underWritingQuestionsId);
        this.$modal.hide("underWritingQuestionsDeleteModal");
        this.successMessage = "UnderWritingQuestion deleted successfully";
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    tableActionHandler(action: string) {
      if (action === "Import Underwriting Questions") {
        this.$modal.show("uploadUnderWritingQuestionsImportFileModal");
      } else if (action === "All Activities") {
        this.$router
          .push(`/admin/underwriting-question/activities`)
          .catch(() => {});
      }
    }
  },
  computed: {
    ...underWritingQuestionMapState(["underWritingQuestions"]),
    ...underWritingQuestionMapGetters([
      "getTotalRecords",
      "getUnderWritingQuestionLinks"
    ]),
    ...underWritingQuestionMapState(["makingApiRequest", "links"])
  }
});
